/* global HOTJAR_SITE_ID, HOTJAR_VERSION */

import Hotjar from '@hotjar/browser'

const isUserPresent = () => Boolean(document.querySelector('meta[name="user-id"]'))

const setUserParams = () => {
  const userEmail = document.querySelector('meta[name="user-email"]')?.getAttribute('content')
  const organizationId = document.querySelector('meta[name="organization-id"]')?.getAttribute('content')
  const organizationName = document.querySelector('meta[name="organization-name"]')?.getAttribute('content')

  return { userEmail, organizationId, organizationName }
}

const initHotjar =  () => {
  if (HOTJAR_SITE_ID && HOTJAR_VERSION) {
    Hotjar.init(HOTJAR_SITE_ID, HOTJAR_VERSION)

    if (isUserPresent) {
      const userId = document.querySelector('meta[name="user-id"]').getAttribute('content')
      const userParams = setUserParams()

      Hotjar.identify(userId, userParams)
    }
  }
}

initHotjar()
